import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import TableDetails from "./TableDetails";
import FileUpload from "./FileUpload";
import { handleFileUpload } from "./handleFileUpload";
import SourcesTable from "./SourcesTable";
import { ssePostRequest } from "./httpUtils";
import { highlightUtil } from "./highlightUtil";
import Viewer from "./Viewer";

/**
 * GenericExtractor Component
 * ========================
 *
 * A versatile React component for extracting and displaying data from various sources.
 * It supports three main types: tables, 10K reports, and file uploads.
 *
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.type="tables"] - Type of extraction ('tables', '10k', or 'upload')
 * @param {Array} [props.successfulFileUploads] - Array of successful file upload objects
 * @param {Function} [props.setSuccessfulFileUploads] - Function to update successful file uploads
 * @param {Array} [props.filesDropped] - Array of dropped files
 * @param {Function} [props.setFilesDropped] - Function to update dropped files
 * @param {Object} [props.appInstanceData] - Application instance data
 *
 * @returns {React.ReactElement} - JSX element representing the GenericExtractor component
 *
 * @note If `successfulFileUploads` and `setSuccessfulFileUploads` are not provided, the component will use internal state for file uploads.
 * @note If `filesDropped` and `setFilesDropped` are not provided, the component will use internal state for dropped files.
 * @note If `user` is not provided, the component will not be able to perform user-specific operations.
 * @note If `appInstanceData` is not provided, the component will not display application-specific information.
 *
 * @example
 * // Usage for 10K Extractor
 * <Route
 *   path="/tenk_extractor/:instance_id"
 *   element={
 *     <ProtectedRoute key="Footnote Extractor">
 *       <GenericExtractor type="10k" />
 *     </ProtectedRoute>
 *   }
 * />
 *
 * @example
 * // Usage for All Tables (PDF Tables to CSVs)
 * <Route
 *   path="/pdf_tables_to_csvs/:instance_id"
 *   element={
 *     <ProtectedRoute key="All Tables">
 *       <GenericExtractor type="tables" />
 *     </ProtectedRoute>
 *   }
 * />
 *
 * @example
 * // Usage with custom state management
 * const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
 * const [filesDropped, setFilesDropped] = useState([]);
 *
 * <GenericExtractor
 *   type="tables"
 *   successfulFileUploads={successfulFileUploads}
 *   setSuccessfulFileUploads={setSuccessfulFileUploads}
 *   filesDropped={filesDropped}
 *   setFilesDropped={setFilesDropped}
 * />
 *
 * @example
 * // Usage with Clerk user and appInstanceData
 * <GenericExtractor
 *   type="10k"
 *   user={user}
 *   appInstanceData={appInstanceData}
 * />
 */
const GenericExtractor = ({
  successfulFileUploads: _successfulFileUploads,
  setSuccessfulFileUploads: _setSuccessfulFileUploads,
  filesDropped: _filesDropped,
  setFilesDropped: _setFilesDropped,
  type = "tables", // one of "tables", "10k", "upload"
}) => {
  let successfulFileUploads;
  let setSuccessfulFileUploads;
  let filesDropped;
  let setFilesDropped;

  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable

  const fileUploads = useState([]);
  if (
    _successfulFileUploads === undefined &&
    _setSuccessfulFileUploads === undefined
  ) {
    successfulFileUploads = fileUploads[0];
    setSuccessfulFileUploads = fileUploads[1];
  } else {
    successfulFileUploads = _successfulFileUploads;
    setSuccessfulFileUploads = _setSuccessfulFileUploads;
  }

  const droppedFiles = useState(false);
  if (_filesDropped === undefined && _setFilesDropped === undefined) {
    filesDropped = droppedFiles[0];
    setFilesDropped = droppedFiles[1];
  } else {
    filesDropped = _filesDropped;
    setFilesDropped = _setFilesDropped;
  }

  const [currentPDFURL, setCurrentPDFURL] = useState(null); // We can soon get rid of currentPDFURL as it's part of currentSource
  const [currentSource, setCurrentSource] = useState(null);
  const [fileType, setFileType] = useState("");

  const [responseSource, setResponseSource] = useState(null);
  const [highlightAreas, setHighlightAreas] = useState([]);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [elements, setElements] = useState([]);
  const [filesUploading, setFilesUploading] = useState([]);

  const [activePageNumber, setActivePageNumber] = useState(null);

  const [extractedData, setExtractedData] = useState({
    savedElements: [],
    selectedIndex: null,
  });

  const currentElement = useMemo(() => {
    return extractedData.selectedIndex !== null
      ? extractedData.savedElements[extractedData.selectedIndex]
      : null;
  }, [extractedData.selectedIndex, extractedData.savedElements]);

  const selectedFileID = useMemo(() => {
    return currentElement ? currentElement.fileID : null;
  }, [currentElement]);

  const selectElementByFileID = useCallback(
    (fileID) => {
      setExtractedData((prevData) => {
        const index = prevData.savedElements.findIndex(
          (element) => element.fileID === fileID
        );
        return {
          ...prevData,
          selectedIndex: index,
        };
      });
    },
    [extractedData.savedElements]
  );

  const resetExtractedData = useCallback(() => {
    setExtractedData({
      savedElements: [],
      selectedIndex: null,
    });
  }, []);

  const reset = () => {
    resetExtractedData();
    setCurrentSource(null);
    setFilesDropped(false);
  };

  const getTables = async (fileID, fileName) => {
    try {
      ssePostRequest(
        "/do_docintel",
        {
          file: fileID,
          filename: fileName,
        },
        {
          onStatus: (jsonPayload) => {
            console.log("Status:", jsonPayload);
          },
          onFinal: (jsonPayload) => {
            console.log("Final:", jsonPayload);
            // Process the final response data
            setElements(jsonPayload.value);
            console.log("Tables successfully parsed.");
          },
          onError: (error) => {
            console.error("Error:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setHighlightAreasInternal = (element) => {
    setHighlightAreas([
      {
        file_id: fileIDs[0],
        pageIndex: element.page_number - 1,
        height: (element.height / element.page_height) * 100,
        width: (element.width / element.page_width) * 100,
        left: (element.left / element.page_width) * 100,
        top: (element.top / element.page_height) * 100,
      },
    ]);
    jumpToPage(element.page_number - 1);
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        if (type === "tables") setPDFDetails(null);
        setFilesDropped(true);

        if (responseSource && type === "tables") {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const beforePostAction = ({ file }) => {
        console.log("Calling beforePostAction");
        setFilesUploading((previousFiles) => [...previousFiles, file]);
      };

      const thenAction = ({ fileID, fileType, response, file }) => {
        console.log("XG Running then action");
        setFilesUploading((previousFiles) =>
          previousFiles.filter((f) => f !== file)
        );
        if (type === "tables") setFileIDs([fileID]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);

        console.log("XG About to run getTables");
        getTables(fileID, response.filename, fileType);
        console.log("XG Finished running getTables");
      };

      handleFileUpload(
        acceptedFiles,
        { fileType },
        {
          preAction,
          thenAction,
          catchAction,
          beforePostAction,
        }
      );
    },
    [fileIDs, fileType]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      if (type === "tables") {
        setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
        setCurrentPDFURL(successfulFileUploads[0].data["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.
      }
      setCurrentSource(successfulFileUploads[0]);
    } else {
      if (type === "tables") {
        setCurrentPDFURL(null);
        setCurrentSource(null);
        setPDFDetails(null);
        setFileIDs([]);
        setFilesDropped(false);
      } else if (type === "10k" || type === "upload") {
        reset();
      }
    }
  }, [successfulFileUploads]);

  useEffect(() => {
    if (
      successfulFileUploads.length > 0 &&
      selectedFileID &&
      (type === "10k" || type === "upload")
    ) {
      const filtered = successfulFileUploads.filter(
        (file) => file.data["file_id"] === selectedFileID
      )[0];
      setCurrentSource(filtered);
    }
  }, [selectedFileID, successfulFileUploads]);

  useEffect(() => {
    if (currentSource && (type === "10k" || type === "upload")) {
      selectElementByFileID(currentSource.data["file_id"]);
    }
  }, [currentSource]);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data?.url
  );

  const isSourceParsed = useMemo(() => {
    if (!currentSource || !extractedData) {
      return false;
    }
    return extractedData.savedElements.some(
      (element) => element.fileID === currentSource.data["file_id"]
    );
  }, [currentSource, extractedData]);

  const isUploading = filesUploading.length > 0;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <div className="m-5">
        <h1 className="text-2xl font-bold mb-5">
          <div className="flex justify-between items-center w-full">
            Extract Text and Translate (Granular)
          </div>
        </h1>

        {(((type === "10k" || type === "upload") && !filesDropped) ||
          (type === "tables" && fileIDs.length == 0 && !filesDropped)) && (
          <FileUpload onDrop={onDrop} />
        )}

        <div className="mt-2">
          {filesDropped && (
            <>
              <p>
                <SourcesTable
                  successfulFileUploads={successfulFileUploads}
                  currentPDFURL={type === "tables" ? currentPDFURL : undefined}
                  setCurrentPDFURL={
                    type === "tables" ? setCurrentPDFURL : undefined
                  }
                  currentSource={currentSource}
                  setCurrentSource={setCurrentSource}
                  fileType={type === "tables" ? fileType : undefined}
                  setSuccessfulFileUploads={setSuccessfulFileUploads}
                />
              </p>

              {isUploading && <p className="text-sm">Uploading...</p>}

              {type === "tables" &&
                fileIDs.length > 0 &&
                !pdfDetails &&
                Object.keys(elements ?? {}).length === 0 && (
                  <p className="text-sm">
                    Parsing{fileIDs.length > 1 ? "s" : null}
                    ...
                  </p>
                )}

              {(type === "10k" ||
                type === "upload" ||
                (type === "tables" &&
                  (pdfDetails || Object.keys(elements ?? {}).length > 0))) && ( // EDIT THIS
                <div className="flex h-screen">
                  <div className="flex-1 p-4 h-screen">
                    {currentSource && (
                      <Viewer
                        fileUrl={currentSource.data["url"]}
                        plugins={[
                          highlightPluginInstance,
                          pageNavigationPluginInstance,
                        ]}
                      />
                    )}
                  </div>

                  <div className="overflow-y-scroll h-screen w-1/2">
                    {type === "tables" && (
                      <>
                        {Object.keys(elements ?? {}).length > 0 &&
                          Object.entries(
                            elements["paragraphs"].reduce((acc, paragraph) => {
                              const pageNumber = paragraph["page_number"];
                              if (!acc[pageNumber]) {
                                acc[pageNumber] = [];
                              }
                              acc[pageNumber].push(paragraph);
                              return acc;
                            }, {})
                          ).map(([pageNumber, paragraphs]) => (
                            <div key={pageNumber}>
                              <h1
                                onClick={() => {
                                  activePageNumber != pageNumber
                                    ? setActivePageNumber(pageNumber)
                                    : setActivePageNumber(null);
                                }}
                              >
                                <b>Page {pageNumber}</b>
                              </h1>
                              {activePageNumber === pageNumber && (
                                <>
                                  <br />
                                  {paragraphs.map((paragraph, index) => (
                                    <div
                                      key={index}
                                      className="flex justify-between space-x-4"
                                      onMouseEnter={() => {
                                        console.log("Mouse enter");
                                        console.log(paragraph);
                                        console.log(fileIDs[0]);
                                        setHighlightAreasInternal(paragraph);
                                      }}
                                    >
                                      <p className="text-xs w-1/2">
                                        {paragraph["text"]}
                                      </p>
                                      <p className="text-xs w-1/2 text-right">
                                        {paragraph["text_english"]}
                                      </p>
                                      <br />
                                      <br />
                                      <br />
                                    </div>
                                  ))}

                                  <hr />
                                </>
                              )}
                            </div>
                          ))}

                        {/* {Object.keys(elements ?? {}).length > 0 &&
                          elements["tables"].map((table) => (
                            <>
                              <TableDetails
                                tableDetails={table.cells}
                                setHighlightAreasInternal={
                                  setHighlightAreasInternal
                                }
                              />
                              <br />
                            </>
                          ))} */}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default GenericExtractor;
