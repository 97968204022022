import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Link } from "react-router-dom";
import About from "./About";
import GenericExtractor from "./GenericExtractor";
import "./App.css";
import "./index.css";
import Logo from "./logo.webp";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";

const navigation = [
  { name: "Home", href: "/" },
  {
    name: "Document Analysis",
    children: [
      { name: "Extract Text and Translate (Granular)", href: "/extractor" },
      { name: "Translate (Crude)", href: "/pdf_translator", disabled: true },
    ],
  },
  {
    name: "Audio/Video Analysis",
    children: [
      { name: "Transcribe Audio/Video", href: "/transcribe", disabled: true },
      { name: "Save from YouTube", href: "/save_youtube", disabled: true },
    ],
  },
  {
    name: "Database Lookup",
    children: [
      { name: "US Companies", href: "/edgar", disabled: true },
      { name: "UK Companies", href: "/edgar", disabled: true },
      { name: "US 10K Filings", href: "/edgar", disabled: true },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function App() {
  const { isSignedIn, user, isLoaded } = useUser();
  const location = useLocation();
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-black">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img alt="Your Company" src={Logo} className="h-12 w-12" />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) =>
                      item.children ? (
                        <Menu as="div" className="relative" key={item.name}>
                          {({ open }) => (
                            <>
                              <MenuButton
                                className={classNames(
                                  "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "flex items-center rounded-md px-3 py-2 text-sm font-medium"
                                )}
                              >
                                {item.name}
                                {open ? (
                                  <ChevronUpIcon className="ml-1 h-5 w-5" />
                                ) : (
                                  <ChevronDownIcon className="ml-1 h-5 w-5" />
                                )}
                              </MenuButton>
                              <MenuItems className="absolute z-10 mt-2 w-48 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                {item.children.map((child) => (
                                  <MenuItem key={child.name}>
                                    {({ active }) => (
                                      <Link
                                        to={child.href}
                                        className={classNames(
                                          child.href == location.pathname
                                            ? "bg-gray-100"
                                            : "",
                                          "block px-4 py-2 text-sm text-gray-700",
                                          child.disabled ? "strikethrough" : ""
                                        )}
                                      >
                                        {child.name}
                                      </Link>
                                    )}
                                  </MenuItem>
                                ))}
                              </MenuItems>
                            </>
                          )}
                        </Menu>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.href}
                          aria-current={
                            item.href == location.pathname ? "page" : undefined
                          }
                          className={classNames(
                            item.href == location.pathname
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <SignedOut>
                    <SignInButton>
                      <button className="text-white">Sign In</button>
                    </SignInButton>
                  </SignedOut>
                  <SignedIn>
                    <UserButton />
                  </SignedIn>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) =>
                item.children ? (
                  <DisclosureButton
                    key={item.name}
                    as="div"
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    <span>{item.name}</span>
                    <ChevronDownIcon className="ml-2 inline h-5 w-5" />
                    <div className="mt-2 ml-4 space-y-1">
                      {item.children.map((child) => (
                        <Link
                          key={child.name}
                          to={child.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {child.name}
                        </Link>
                      ))}
                    </div>
                  </DisclosureButton>
                ) : (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    aria-current={
                      item.href == location.pathname ? "page" : undefined
                    }
                    className={classNames(
                      item.href == location.pathname
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </DisclosureButton>
                )
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>

        <header className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">
              Investigative Toolkit
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <SignedOut>
                      Click "Sign In" on the top right to use the tools. 
                    </SignedOut>
                    <SignedIn>
                      Welcome{user?.firstName ? ", " + user?.firstName : null}!
                    </SignedIn>
                  </>
                }
              />
              <Route path="/about" element={<About />} />
              <Route path="/extractor" element={<GenericExtractor />} />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
}
