import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { PostHogProvider } from "posthog-js/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log("hostname", window.location.hostname);

const clerkKey = window.location.hostname.endsWith("investigativetoolkit.app")
  ? "pk_live_Y2xlcmsuaW52ZXN0aWdhdGl2ZXRvb2xraXQuYXBwJA"
  : "pk_test_aGVscGVkLWhhcmUtNjIuY2xlcmsuYWNjb3VudHMuZGV2JA";

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={clerkKey} afterSignOutUrl="/">
      <PostHogProvider
        apiKey="phc_2MN5PZkefJsudrDiFfLF8XrX1QkOjDWARTsBzpHRatO"
        options={{
          api_host: "https://us.i.posthog.com",
        }}
      >
        <Router>
          <App />
        </Router>
      </PostHogProvider>
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
