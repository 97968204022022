import React from "react";
import { useDropzone } from "react-dropzone";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

export const Dropzone = ({
  onDrop,
  dragText = "Drag your document here, or click to browse.",
}) => {
  const pdfExtConst = ["pdf"];
  const docxExtConst = ["docx"];
  const xlsxExtConst = ["xlsx"];
  const pdfMimeType = "application/pdf";
  const docxMimeType =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const xlsxMimeType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [pdfMimeType]: pdfExtConst,
      [docxMimeType]: docxExtConst,
      [xlsxMimeType]: xlsxExtConst,
    },
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={`h-48 border-3 border-dashed hover:border-solid border-grey rounded-lg flex justify-center items-center text-center p-5 mr-5 ${
        isDragActive ? "bg-customHighlightColor text-white" : "text-grey"
      }`}
      data-dropzone
      id="dropzone"
    >
      <input {...getInputProps()} />
      <div
        className={`flex space-x-4 flex-col items-center justify-center space-y-4`}
      >
        <ArrowUpOnSquareIcon className="h-10 w-10 text-sm bg-customHighlightColor text-white rounded-full p-2" />
        <br />
        <p>{dragText}</p>
      </div>
    </div>
  );
};

export default Dropzone;
